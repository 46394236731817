:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 832px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
}
.image_traced {
  position: absolute;
  width: 35.16%;
  height: 97.36%;
  top: 1.32%;
  left: 1.17%;
  background: url(../assets/register/images/40407d34-d04f-4f6d-850c-11853e4ca1ec.png)
    no-repeat center;
  background-size: 100% 100%;
}
.logo_box {
  position: absolute;
  width: 540px;
  height: 100px;
  top: 40%;
  left: 50%;
  /* background: #d9d9d9; */
  transform: translate(-50%, -316%);
  z-index: 10; /* 确保在前面 */
  /* color: #000; 文本颜色 */
}
.koi_speak {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 84px;
  top: 14px;
  left: calc(50% - 161px);
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 60px;
  font-weight: 900;
  line-height: 84px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}
.rectangle {
  position: absolute;
  width: 540px;
  height: 580px;
  top: 50%;
  left: 50%;
  font-size: 0px;
  background: #ffffff;
  transform: translate(-50%, -44.14%);
  z-index: 4;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 #dfe1eb;
}
.email_input {
  display: block;
  position: relative;
  height: 28px;
  margin: 30px 0 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 29;
}
.rectangle_1 {
  position: relative;
  width: 410px;
  height: 30px;
  margin: 5px 0 0 65px;
  border-bottom: 1px solid #8f8f8f;
  z-index: 32;
}
.email_example {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  width: 440px;
  top: 1px;
  left: 0;
  color: black;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  border: none;       /* 去掉边框 */
  text-align: left;

  white-space: nowrap;
  z-index: 31;
}

.email_example::placeholder {
  color: #bfbfbf;
}

.show_password{
  cursor: pointer;
  position: relative;
  top: -25px;
  /* left: 80px; */
  width: 25px;
  height: 15.234px;
  left: 440px;
  background: url(../assets/login/images/open.png)
    no-repeat center;
  background-size: contain;
  z-index: 90;
}

.show_confirm_password{
  position: relative;
  cursor: pointer;
  top: -25px;
  width: 25px;
  height: 15.234px;
  left: 440px;
  background: url(../assets/login/images/open.png)
    no-repeat center;
  background-size: contain;
  z-index: 90;
}

.account_exists {
  display: block;
  position: relative;
  height: 25px;
  margin: 5px 0 0 65px;
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 33;
}

.rectangle_2 {
  position: relative;
  width: 410px;
  height: 30px;
  margin: 5px 0 0 65px;
  border-bottom: 1px solid #8f8f8f;
  z-index: 32;
}
.password {
  display: block;
  position: relative;
  height: 28px;
  margin: 45px 0 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  width: 440px;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 21;
}

.password_input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 1px;
  left: 0;
  color: black;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  border: none;       /* 去掉边框 */
  text-align: left;

  white-space: nowrap;
  z-index: 31;
}

.password_input::placeholder {
  color: #bfbfbf;
}


.hidden_password {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 1px;
  left: 0;
  color: #bfbfbf;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}
.group {
  position: absolute;
  width: 25px;
  height: 15px;
  top: 7px;
  left: 385px;
  background: url(../assets/register/images/6c3ae1ed-60c6-4dd0-9e53-3dbcb140c4cb.png)
    no-repeat center;
  background-size: cover;
  z-index: 23;
}
.password_requirements {
  display: block;
  position: relative;
  height: 25px;
  margin: 5px 0 0 65px;
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 27;
}
.confirm_password {
  display: block;
  position: relative;
  height: 28px;
  margin: 45px 0 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}
.rectangle_3 {
  position: relative;
  width: 410px;
  height: 30px;
  margin: 5px 0 0 65px;
  border-bottom: 1px solid #8f8f8f;
  z-index: 17;
}
.password_confirm {
  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 28px;
    top: 1px;
    left: 0;
    color: balck;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    border: none;       /* 去掉边框 */
    text-align: left;
  
    white-space: nowrap;
    z-index: 31;
  
}

.password_confirm::placeholder {
  color: #bfbfbf;
}

.hidden_text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 1px;
  left: 0;
  color: #bfbfbf;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 18;
}
.group_4 {
  position: absolute;
  width: 25px;
  height: 15px;
  top: 7px;
  left: 385px;
  background: url(../assets/register/images/c8635a62-bfa7-4a72-92cc-bac7ce063cf3.png)
    no-repeat center;
  background-size: cover;
  z-index: 16;
}
.password_mismatch {
  display: block;
  position: relative;
  height: 25px;
  margin: 5px 0 0 65px;
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 14;
}
.invite_code {
  display: block;
  position: relative;
  height: 28px;
  margin: 15px 0 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 8;
}
.rectangle_5 {
  position: relative;
  width: 410px;
  height: 30px;
  margin: 5px 0 0 65px;
  border-bottom: 1px solid #8f8f8f;
  z-index: 10;
}
.abdmo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 1px;
  left: 0;
  color: black;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  border: none;       /* 去掉边框 */
  text-align: left;

  white-space: nowrap;
  z-index: 31;
}

.abdmo::placeholder{
  color: #bfbfbf;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 410px;
  margin: 60px 0 0 65px;
  padding: 10px 10px 10px 10px;
  top: -30px;
  cursor: pointer;
  background: #505f98;
  border: none;
  z-index: 5;
  border-radius: 15px;
}
.register {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 34px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
.system_maintenance {
  display: block;
  position: relative;
  height: 25px;
  margin: 10px 0 0 171px;
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 38;
}
.agreement_text {
  position: absolute;
  width: 288px;
  height: 25px;
  top: 450px;
  left: 90px;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 35;
}
.and {
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.user_agreement {
  cursor: pointer;
  position: relative;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.and_6 {
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.user_agreement_7 {
  cursor: pointer;
  position: relative;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
.rectangle_8 {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 448px;
  left: 63px;
  border: 1px solid #505f98;
  z-index: 37;
  border-radius: 5px;
}
.login_account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 755px;
  left: 830px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
