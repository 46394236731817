/* Drop.module.css */

.dropdown {
    top: 460px;
    left: 45px;
    position: absolute;
    display: inline-block;
}
.description {
    position: relative;
    top: 10px;
}
.description_text{
    position:relative;
    font-family: Nunito Sans, var(--default-font-family);
    color: black;
    font-size: 18px;
    font-weight: 800;

}

.input {
    flex-shrink: 0;
    /* left: 10px; */
    position: relative;
    width: 193px;
    height: 25px;
    cursor: pointer;
    color: #909090;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    white-space: nowrap;
    background: transparent;
    border: none;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 203px;
    margin: 0 auto;
    left: -38px;
    top: 20px;
    padding: 5px 5px 5px 5px;
    border: 2px solid #505f98;
    border-radius: 8px;
}

.input {
    flex-shrink: 0;
    /* left: 10px; */
    position: relative;
    width: 193px;
    height: 25px;
    cursor: pointer;
    color: #909090;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    white-space: nowrap;
    background: transparent;
    border: none;
}

.confirmButton {
    /* padding: 8px 16px; */
    position: absolute;
    width: 58px;
    background-color: #505F98;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    left: 225px;
    top: 47px;
    border-radius: 5px;
}

.ok {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
  }

/* .confirmButton:hover {
    background-color: rgba(83, 95, 148, 0.1);
} */

.message {
    margin-top: 30px;
    margin-left: 8px;
    font-size: 18px;
}
