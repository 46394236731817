:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 832px;
  margin: 0 auto;
}
.macbook_air_home {
  position: absolute;
  width: 1280px;
  height: 832px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  overflow: hidden;
}
.frame_1 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
  position: relative;
  width: 270px;
  margin: 787px 0 0 505px;
  z-index: 1;
}
.about_us {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 2;
}
.about_us_2 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.about_us_3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 4;
}
.about_us_terms {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 5;
}
.about_us_4 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 6;
}
.about_us_privacy {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 7;
}
.about_us_5 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 8;
}
.about_us_help {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 9;
}
.logo_tentative {
  position: absolute;
  width: 212px;
  height: 120px;
  top: 30px;
  left: 30px;
  background: url(../assets/payment/images/13d134b8c6623a3c97a4772a4dbec54b24cacf65.png)
    no-repeat center;
  background-size: cover;
  z-index: 34;
  border-radius: 15px;
}
.flex_row_fde {
  position: absolute;
  width: 1220px;
  height: 740px;
  top: 30px;
  left: 30px;
  z-index: 35;
}
.frame_6 {
  position: absolute;
  width: 978px;
  height: 736px;
  top: 0;
  left: 242px;
  background: #ffffff;
  z-index: 35;
  overflow: hidden;
  border-radius: 15px;
}
.group {
  position: relative;
  width: 907px;
  height: 62px;
  margin: 20px 0 0 36px;
  z-index: 36;
}
.frame_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 209px;
  height: 62px;
  top: 0;
  left: 50%;
  padding: 10px 10px 10px 10px;
  transform: translate(-50.24%, 0);
  z-index: 37;
}
.select_points_plan {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 42px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 42px;
  text-align: left;
  white-space: nowrap;
  z-index: 38;
}
.divider {
  position: absolute;
  width: 338px;
  top: 31px;
  left: 0;
  z-index: 39;
}
.line {
  position: absolute;
  width: 100.89%;
  height: 3px;
  top: 0;
  left: -0.44%;
  background: url(../assets/payment/images/5749b7ad-e9df-4195-8e83-c5669dd3c585.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 40;
}
.divider_8 {
  position: absolute;
  width: 338px;
  top: 31px;
  left: 569px;
  z-index: 41;
}
.line_9 {
  position: absolute;
  width: 100.89%;
  height: 3px;
  top: 0;
  left: -0.44%;
  background: url(../assets/payment/images/d9428525-1339-4f64-9aa0-bdfd11c8429a.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 42;
}
.group_a {
  position: relative;
  width: 882px;
  height: 218px;
  margin: 20px 0 0 48px;
  z-index: 43;
  border-radius: 15px;
}
.frame_b {
  position: absolute;
  width: 198px;
  height: 218px;
  top: 0;
  left: 50%;
  background: #f5f5f5;
  transform: translate(-222.73%, 0);
  z-index: 44;
  overflow: hidden;
  border-radius: 15px;
}
.frame_c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 107px;
  margin: 15px 0 0 45.5px;
  padding: 10px 10px 10px 10px;
  z-index: 45;
}
.currency {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 67px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 48px;
  font-weight: 900;
  line-height: 67px;
  text-align: left;
  white-space: nowrap;
  z-index: 46;
}
.frame_d {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 139px;
  margin: 0 0 0 29px;
  padding: 10px 10px 10px 10px;
  z-index: 47;
}
.points_ai_exam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 119px;
  height: 81px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  text-overflow: initial;
  z-index: 48;
  overflow: hidden;
}
.frame_e {
  position: absolute;
  width: 198px;
  height: 218px;
  top: 0;
  left: 50%;
  background: #f5f5f5;
  transform: translate(-107.58%, 0);
  z-index: 49;
  overflow: hidden;
  border-radius: 15px;
}
.frame_f {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 107px;
  margin: 15px 0 0 45.5px;
  padding: 10px 10px 10px 10px;
  z-index: 50;
}
.currency_10 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 67px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 48px;
  font-weight: 900;
  line-height: 67px;
  text-align: left;
  white-space: nowrap;
  z-index: 51;
}
.frame_11 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 139px;
  margin: 0 0 0 29px;
  padding: 10px 10px 10px 10px;
  z-index: 52;
}
.score_ai_permanent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 119px;
  height: 81px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  text-overflow: initial;
  z-index: 53;
  overflow: hidden;
}
.frame_12 {
  position: absolute;
  width: 198px;
  height: 218px;
  top: 0;
  left: 50%;
  background: #f5f5f5;
  /* border: 5px solid #505f98; */
  transform: translate(7.58%, 0);
  z-index: 54;
  overflow: hidden;
  border-radius: 15px;
}
.frame_13 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 107px;
  margin: 15px 0 0 45.5px;
  padding: 10px 10px 10px 10px;
  z-index: 55;
}
.price {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 67px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 48px;
  font-weight: 900;
  line-height: 67px;
  text-align: left;
  white-space: nowrap;
  z-index: 56;
}
.frame_14 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 157px;
  margin: 5px 0 0 20px;
  padding: 10px 10px 10px 10px;
  z-index: 57;
}
.score_ai_instant {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 137px;
  height: 81px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  text-overflow: initial;
  z-index: 58;
  overflow: hidden;
}
.frame_15 {
  position: absolute;
  width: 198px;
  height: 218px;
  top: 0;
  left: 50%;
  background: #f5f5f5;
  transform: translate(122.73%, 0);
  z-index: 59;
  overflow: hidden;
  border-radius: 15px;
}
.frame_16 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 136px;
  margin: 15px 0 0 31px;
  padding: 10px 10px 10px 10px;
  z-index: 60;
}
.price_17 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 67px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 48px;
  font-weight: 900;
  line-height: 67px;
  text-align: left;
  white-space: nowrap;
  z-index: 61;
}
.frame_18 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 150px;
  margin: 0 0 0 24px;
  padding: 10px 10px 10px 10px;
  z-index: 62;
}
.score_ai_permanent_19 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 130px;
  height: 81px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  text-overflow: initial;
  z-index: 63;
  overflow: hidden;
}
.frame_1a {
  position: relative;
  width: 888px;
  height: 100px;
  margin: 23px 0 0 45px;
  background: #f7f7fa;
  z-index: 69;
  overflow: hidden;
  border-radius: 15px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 279px;
  height: 47px;
  margin: 15px 0 0 305px;
  padding: 0 69px 0 69px;
  cursor: pointer;
  background: transparent;
  border: 3px solid #505f98;
  z-index: 71;
  border-radius: 15px;
}
.work_in_progress {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #3e476f;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 72;
}
.customized_tracking_learning {
  display: block;
  position: relative;
  height: 36px;
  margin: 0 0 0 200px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  white-space: nowrap;
  z-index: 70;
}
.invitation_code {
  display: block;
  position: relative;
  height: 27px;
  margin: 23px 0 0 48px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 64;
}
.frame_1b {
  position: relative;
  width: 236px;
  height: 64px;
  margin: 65px 0 0 160px;
  top: 130px;
  left: 30px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 65;
  padding: 15px 20px 15px 20px;
  border-radius: 15px;
  left: 220px;
  transition: transform 0.2s ease, box-shadow 0.2s ease; 
}
.frame_1b:hover {
  transform: scale(0.95); /* 按下去的缩放效果 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 模拟按下的阴影效果 */
}
.small_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 236px;
  height: 64px;
  top: 0;
  left: 0;
  padding: 15px 20px 15px 20px;
  background: #505f98;
  z-index: 67;
  border-radius: 15px;
}
.jump_payment {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 34px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  z-index: 68;
}
.rectangle {
  position: absolute;
  width: 136px;
  height: 74px;
  top: 3px;
  left: 0;
  background: #3e476f;
  z-index: 66;
  border-radius: 15px;
}
.sidebar {
  position: absolute;
  width: 212px;
  height: 601px;
  top: 139px;
  left: 0;
  background: #ffffff;
  z-index: 10;
  border-radius: 15px;
}
.frame_1c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 185px;
  height: 211px;
  margin: 20px 0 0 14px;
  z-index: 18;
}
.sidebar_my_home {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 19;
  border-radius: 15px;
}
.sidebar_my_home:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.home {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 20;
  overflow: hidden;
}
.icon {
  position: relative;
  width: 18.667px;
  height: 17.625px;
  margin: 0.88px 0 0 0.67px;
  background: url(../assets/payment/images/0564e2fe-7b8c-4b4f-9658-14c54727f762.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 21;
}
.my_home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 22;
}
.sidebar_my_home_1d {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 23;
  border-radius: 15px;
}
.sidebar_my_home_1d:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.mock_exam {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 24;
}
.trophy {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 25;
  overflow: hidden;
}
.icon_1e {
  position: relative;
  width: 18.667px;
  height: 18.667px;
  margin: 0.67px 0 0 0.67px;
  background: url(../assets/payment/images/1cf24d08-4ccc-496b-9a67-c2661d577722.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 26;
}
.my_homepage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 27;
}
.sidebar_my_homepage {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 28;
  border-radius: 15px;
}
.sidebar_my_homepage:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.oral_practice {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/payment/images/e3ace590-f120-417e-9aa6-e8ee25f28a31.png)
    no-repeat center;
  background-size: cover;
  z-index: 29;
}
.my_homepage_1f {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 30;
}
.sidebar_my_homepage_20 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 31;
  border-radius: 15px;
}
.sidebar_my_homepage_20:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.mock_exam_record {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/payment/images/2cd814c2-2633-4b73-b389-d1bc2007bb4e.png)
    no-repeat center;
  background-size: cover;
  z-index: 32;
}
.my_homepage_21 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 33;
}
.divider_22 {
  position: relative;
  width: 150px;
  margin: 167px 0 0 25px;
  background: #f7f7fa;
  z-index: 16;
}
.line_23 {
  position: absolute;
  width: 102%;
  height: Infinity%;
  top: -Infinity%;
  left: -1%;
  background: url(../assets/payment/images/5cbfdc77-30ce-4cf4-b107-57f940a9b6c4.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 17;
}
.sidebar_my_homepage_24 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 15px;
  position: relative;
  width: 190px;
  height: 61px;
  margin: 15px 0 0 11px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  background: #dcdfea;
  border: none;
  z-index: 13;
  border-radius: 15px;
}
.sidebar_my_homepage_24:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.my_account {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/payment/images/ff21a5e3-40d0-4654-94ce-fb900ae5acba.png)
    no-repeat center;
  background-size: cover;
  z-index: 14;
}
.my_homepage_25 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 15;
}
.sidebar_my_homepage_26 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 15px;
  position: relative;
  width: 50px;
  margin: 83px 0 0 159px;
  padding: 10px 15px 10px 15px;
  z-index: 11;
  border-radius: 15px;
}
.help {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/payment/images/8d0a5ed5-2480-47f6-a362-1297953d435b.png)
    no-repeat center;
  background-size: cover;
  z-index: 12;
}

.jump_main_container {
  position: relative;
  top: 220px;
  left: 120px;
  width: 578px;
  height: 356px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.jump_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 308px;
  margin: 0 0 0 135px;
  padding: 10px 10px 10px 10px;
  z-index: 81;
}
 .jump_ready_to_go {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 54px;
  color: #535f94;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 36px;
  font-weight: 900;
  line-height: 54px;
  text-align: left;
  white-space: nowrap;
  z-index: 82;
}
.jump_line {
  position: relative;
  width: 521px;
  height: 3px;
  margin: -0.5px 0 0 28.5px;
  /* background: url(../assets/main_page/images/1136faa2-3059-4018-90f1-a03906521e42.png) */
    /* no-repeat center; */
  background-size: 100% 100%;
  z-index: 90;
}
 .jump_text_2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 520px;
  height: 81px;
  margin: 13.5px 0 0 29px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}
 .jump_frame_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 253px;
  margin: 10px 0 0 162px;
  cursor: pointer;
  background: #f7f7fa;
  border: none;
  z-index: 83;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
 .jump_frame_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 164px;
  padding: 5px 0 5px 0;
  z-index: 84;
}
 .jump_my_points {
  flex-shrink: 0;
  position: relative;
  width: 164px;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 85;
}
 .jump_my_points_1 {
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
.jump_points {
  position: relative;
  color: #535f94;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
 .jump_frame_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 110px;
  padding: 0 10px 0 10px;
  z-index: 86;
}
 .jump_frame_3 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 87;
}
 .jump_purchase_score {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 88;
}
 .jump_flex_row_a {
  position: relative;
  width: 473px;
  height: 52px;
  margin: 22px 0 0 53px;
  z-index: 98;
}
 .jump_small_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 47.15%;
  height: 88.71%;
  top: 0;
  left: 0;
  padding: 10px 20px 10px 20px;
  background: #505f98;
  z-index: 94;
  border-radius: 15px;
}
 .jump_i_am_thinking {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 95;
}
 .jump_small_button_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 47.15%;
  height: 88.71%;
  top: 0;
  left: 52.85%;
  padding: 10px 20px 10px 20px;
  background: #d453a8;
  z-index: 98;
  border-radius: 15px;
}
 .jump_start_mock_exam {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 99;
}
.jump_rectangle {
  position: absolute;
  width: 47.15%;
  height: 95.16%;
  top: 4.84%;
  left: 0;
  background: #3e476f;
  z-index: 93;
  border-radius: 15px;
}
.jump_rectangle_5 {
position: absolute;
width: 47.15%;
height: 95.16%;
top: 4.84%;
left: 52.85%;
background: #782c5e;
z-index: 97;
border-radius: 15px;
}
.help_main_container {
  left: 100px;
  top: 150px;
  position: relative;
  width: 346px;
  height: 491px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  z-index: 80;
}
.help_close {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20.085px;
  margin: 16px 0 0 310px;
  background: url(../assets/main_page/images/help_close.png)
    no-repeat center;
  background-size: cover;
  z-index: 3;
}
.help_close:hover {
  border: 2px solid red; /* 红色边框 */
}
.help_add_wechat {
  display: block;
  position: relative;
  height: 25px;
  margin: 8.915px 0 0 119px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.help_get_materials {
  display: block;
  position: relative;
  height: 25px;
  margin: 10px 0 0 47px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
}
.help_image {
  position: relative;
  width: 266px;
  height: 363px;
  margin: 23px 0 0 40px;
  background: url(../assets/main_page/images/help_image.png)
    no-repeat center;
  background-size: cover;
}
