:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1440px;
    height: 1024px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
  }
  .rectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 1440px;
    height: 100px;
    margin: 0 0 0 1px;
    background: #505f98;
    z-index: 24;
  }
  .logo_placeholder {
    flex-shrink: 0;
    position: relative;
    height: 55px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    /* text-align: left; */
    white-space: nowrap;
    z-index: 24;
  }
  .part_two {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 337px;
    height: 56px;
    margin: 30px 0 0 552px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: center;
    white-space: nowrap;
    z-index: 3;
  }
  .time {
    display: block;
    position: relative;
    height: 41px;
    margin: 635px 0 0 680px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    white-space: nowrap;
    z-index: 11;
  }
  .rectangle_1 {
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 60px;
    margin: 38px 0 0 1200px;
    background: #d9d9d9;
    z-index: 13;
    border-radius: 30px;
  }
  .next {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: 50%;  /* 父容器高度的一半 */
    left: 50%; /* 父容器宽度的一半 */
    transform: translate(-50%, -50%); /* 向上和向左偏移自身宽高的一半 */
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    /* text-align: left; */
    white-space: nowrap;
    z-index: 15;
  }
  .flex_column {
    position: absolute;
    width: 9px;
    height: 18px;
    top: 21px;
    left: 113px;
    z-index: 17;
  }
  .line {
    position: relative;
    width: 11.385px;
    height: 12.081px;
    margin: -1.754px 0 0 -1.192px;
    background: url(../assets/record_pages/part2/c5300f3a-e1f2-421c-8a13-434ab5edd3c9.png)
      no-repeat center;
    background-size: cover;
    z-index: 16;
  }
  .line_2 {
    position: relative;
    width: 10.906px;
    height: 12.1px;
    margin: -2.662px 0 0 -1.178px;
    background: url(../assets/record_pages/part2/1b52ba07-b186-4d13-a613-1e192c491801.png)
      no-repeat center;
    background-size: cover;
    z-index: 17;
  }
  .describe_activity {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 606px;
    height: 73px;
    top: 243px;
    left: 764px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    z-index: 9;
  }
  .rectangle_3 {
    position: absolute;
    width: 554.556px;
    height: 322px;
    top: 273px;
    left: 50%;
    background: #d9d9d9;
    transform: translate(-108.74%, 0);
    z-index: 5;
  }
  .ellipse {
    position: relative;
    width: 133.869px;
    height: 134.167px;
    margin: 53.667px 0 0 208.107px;
    background: url(../assets/record_pages/part2/4c24dee7-4941-4ad3-85e0-72958ffd98f9.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 7;
  }
  .rectangle_4 {
    position: relative;
    width: 234.27px;
    height: 117.396px;
    margin: 16.771px 0 0 157.907px;
    background: url(../assets/record_pages/part2/390d9e1a-006f-47c2-acff-6231aeae6132.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 8;
  }
  .describe_activity_details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 544px;
    height: 231px;
    top: 378px;
    left: 764px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 38.192px;
    text-align: left;
    text-overflow: initial;
    z-index: 10;
    overflow: hidden;
  }
  
  .wave_box {
    position: relative;
    overflow: hidden;
    top: -350px;
    width: 896px;
    height: 96px;
    margin: 55px 0 0 272px;
    z-index: 21;
    /* border: 1px solid black; */
  }
  .clock {
    position: absolute;
    height: 96px;
    top: 0;
    right: 400px;
    left: 400px;
    background: url(../assets/record_pages/part2/54faf87d-f10d-44ae-8e77-e384c5339acf.png)
      no-repeat center;
    background-size: cover;
    z-index: 28;
  }
  .line_5 {
    position: absolute;
    width: 899px;
    height: 3px;
    top: 47px;
    left: 50%;
    background: url(../assets/record_pages/part1/b5e052c0-085f-49f0-a2fb-7eaa920e436c.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-50%, 0);
    z-index: 20;
  }
  .loading_gif {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    z-index: 1001;
    /* background: transparent; */
  }