:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1440px;
    height: 1024px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
  }
  .rectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 1440px;
    height: 100px;
    margin: 0 0 0 0;
    background: #505f98;
    z-index: 2;
  }
  .koi_speak {
    flex-shrink: 0;
    position: relative;
    height: 55px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 2;
  }
  .rectangle_1 {
    position: relative;
    width: 900px;
    height: 660px;
    margin: 80px 0 0 270px;
    font-size: 0px;
    background: #f7f7fa;
    border: 5px solid #505f98;
    z-index: 10;
    overflow: visible auto;
    border-radius: 20px;
  }
  .part_instruction {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 280px;
    height: 110px;
    margin: 40px 0 0 310px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: center;
    text-overflow: initial;
    z-index: 14;
    overflow: hidden;
  }
  .line {
    position: relative;
    width: 750px;
    height: 10px;
    margin: 35px 0 0 75px;
    background: url(../assets/record_pages/instruction/d53e4828-19d6-4aa2-954f-13f8944fdafa.png)
      no-repeat center;
    background-size: cover;
    z-index: 15;
  }
  .general_questions {
    position: relative;
    width: 750px;
    height: 168px;
    margin: 55px 0 0 75px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    z-index: 12;
  }
  .general_questions_2 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }
  .around_5_minutes {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
  }
  .general_questions_3 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }
  .each_answer_ideal {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }
  .around_5_minutes_4 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
  }
  .best_test_environment {
    position: relative;
    width: 750px;
    height: 168px;
    margin: 15px 0 0 75px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    z-index: 13;
  }
  .best_test_recommendation {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }
  .quiet_environment {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
  }
  .improve_quality {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
  }
  .rectangle_5 {
    position: relative;
    width: 150px;
    height: 60px;
    margin: 60px 0 0 1200px;
    background: #505f98;
    z-index: 4;
    border-radius: 30px;
  }
  .next {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 46px);
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 6;
  }
  .flex_column {
    position: absolute;
    width: 9px;
    height: 18px;
    top: 21px;
    left: 113px;
    z-index: 8;
  }
  .line_6 {
    position: relative;
    width: 11.385px;
    height: 12.081px;
    margin: -1.754px 0 0 -1.192px;
    background: url(../assets/record_pages/instruction/f60273a8-ddf9-4182-91b4-c30c2bdff100.png)
      no-repeat center;
    background-size: cover;
    z-index: 7;
  }
  .line_7 {
    position: relative;
    width: 10.906px;
    height: 12.1px;
    margin: -2.662px 0 0 -1.178px;
    background: url(../assets/record_pages/instruction/072c8ccb-a637-486d-b45d-43df1670f062.png)
      no-repeat center;
    background-size: cover;
    z-index: 8;
  }
  