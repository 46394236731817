:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 832px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
}
.traced_image {
  position: absolute;
  width: 35.16%;
  height: 97.36%;
  top: 1.56%;
  left: 0.86%;
  background: url(../assets/forget/images/ebc73b32-4189-4b7a-938f-6e92dc42f00b.png)
    no-repeat center;
  background-size: 100% 100%;
}
.logo_box {
  position: absolute;
  width: 540px;
  height: 100px;
  top: 50%;
  left: 50%;
  /* background: #d9d9d9; */
  transform: translate(-50%, -316%);
  z-index: 10; /* 确保在前面 */
  /* color: #000; 文本颜色 */
}
.koi_speak {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 84px;
  top: 14px;
  left: calc(50% - 161px);
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 60px;
  font-weight: 900;
  line-height: 84px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}
.rectangle {
  position: absolute;
  width: 540px;
  height: 300px;
  top: 50%;
  left: 50%;
  font-size: 0px;
  background: #ffffff;
  transform: translate(-50%, -50%);
  z-index: 3;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 #dfe1eb;
}
.reset_password {
  display: block;
  position: relative;
  height: 34px;
  margin: 30px 0 0 222px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 33.6px;
  text-align: left;
  white-space: nowrap;
  z-index: 11;
}
.password_input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 0;
  left: 310px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 5;
}
.register_email {
  display: block;
  position: relative;
  height: 28px;
  margin: 40px 65px 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: normal;
  z-index: 7;
}
.rectangle_1 {
  position: relative;
  width: 410px;
  height: 30px;
  margin: 5px 0 0 65px;
  border-bottom: 1px solid #8f8f8f;
  z-index: 10;
}
.email_example {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  width: 410px;
  top: 1px;
  left: 0;
  color: balck;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  border: none;       /* 去掉边框 */
  text-align: left;
  white-space: nowrap;
  z-index: 31;
}

.email_example::placeholder{
  color: #bfbfbf;
}

.submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 410px;
  margin: 49px 0 0 65px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background: #505f98;
  border: none;
  z-index: 4;
  border-radius: 15px;
}
.submit {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 34px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  z-index: 5;
}
.login_section {
  position: absolute;
  width: 126px;
  height: 25px;
  top: 596px;
  left: 784px;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 12;
}
.existing_account {
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25.2px;
  text-align: left;
}
.login_button {
  cursor: pointer;
  position: relative;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25.2px;
  text-align: left;
}
