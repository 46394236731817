:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 832px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
}
.traced_image {
  position: absolute;
  width: 35.16%;
  height: 97.36%;
  top: 1.32%;
  left: 1.95%;
  background: url(../assets/login/images/5ba5f4c4-cbe6-444b-ac8a-23af20aec075.png)
    no-repeat center;
  background-size: 100% 100%;
}
.logo_box {
  position: absolute;
  width: 540px;
  height: 100px;
  top: 50%;
  left: 50%;
  /* background: #d9d9d9; */
  transform: translate(-50%, -316%);
  z-index: 10; /* 确保在前面 */
  /* color: #000; 文本颜色 */
}
.koi_speak {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 84px;
  top: 14px;
  left: calc(50% - 161px);
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 60px;
  font-weight: 900;
  line-height: 84px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}

.rectangle {
  position: absolute;
  width: 540px;
  height: 460px;
  top: 50%;
  left: 50%;
  font-size: 0px;
  background: #ffffff;
  transform: translate(-50%, -35.48%);
  z-index: 4;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 #dfe1eb;
}
.email {
  display: block;
  position: relative;
  height: 58px;
  margin: 45px 0 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 24;
}
.email_input {
  position: relative;
  color: black;
  font-family: Microsoft Sans Serif, var(--default-font-family);
  font-size: 15px;
  top: 10px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  letter-spacing: 1px;
  border: none;       /* 去掉边框 */
  outline: none;   
  z-index: 20;
  width: 100%;
  pointer-events: auto; 
}

.email_input::placeholder {
  color: rgba(0, 0, 0, 0.2); /* 占位符文字颜色为浅黑色 */
}

.rectangle_1 {
  position: relative;
  width: 410px;
  height: 45px;
  left: 12%;
  bottom: 8%;
  /* margin: 5px 0 0 65px; */
  border-bottom: 1px solid #8f8f8f;
  z-index: 26;
}
.example_mail {
  position: relative;
  width: 176px;
  height: 28px;
  /* top: 1px;
  left: 0; */
  color: #bfbfbf;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  z-index: 26;
  overflow: hidden;
}
.account_error {
  display: none;
}
.account_error.visible {
  display: block;
  position: relative;
  top: -28px;
  left: 65px;
  /* height: 55px; */
  /* margin: 5px 0 0 65px; */
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 28;
}
.password_error {
  display: none;
}
.password_error.visible {
  display: block;
  position: relative;
  top: 0px;
  left: 65px;
  /* height: 55px; */
  /* margin: 5px 0 0 65px; */
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 28;
}
.password {
  display: block;
  position: relative;
  height: 28px;
  margin: 15px 0 0 65px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
}
.rectangle_2 {
  position: relative;
  width: 410px;
  height: 45px;
  /* margin: 5px 0 0 65px; */
  left: 12%;
  bottom: 1%;
  border-bottom: 1px solid #8f8f8f;
  z-index: 12;
}
.password_input {
  position: relative;
  color: black;
  font-family: Microsoft Sans Serif, var(--default-font-family);
  font-size: 15px;
  top: 18px;
  height: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  letter-spacing: 1px;
  border: none;       /* 去掉边框 */
  outline: none;   
  z-index: 20;
  width: 100%;
  pointer-events: auto; 
}

.password_input::placeholder {
  color: rgba(0, 0, 0, 0.2); /* 占位符文字颜色为浅黑色 */
}

.show_password{
  cursor: pointer;
  position: relative;
  width: 25px;
  height: 15.234px;
  left: 380px;
  background: url(../assets/login/images/open.png)
    no-repeat center;
  background-size: contain;
  z-index: 90;
}


.fhfhfvidlfknbu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 1px;
  left: 0;
  color: #bfbfbf;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}
.ellipse {
  position: absolute;
  width: 20.982px;
  height: 6.234px;
  top: 10px;
  left: 387.313px;
  background: url(../assets/login/images/c88b9b94-1aee-4a6b-a59b-51f56054183d.png)
    no-repeat center;
  background-size: cover;
  z-index: 15;
}
.line {
  position: absolute;
  width: 4.64px;
  height: 4.64px;
  top: 13.041px;
  left: 385px;
  background: url(../assets/login/images/71d6f8b5-a4ea-4efb-9480-572c0d81bb6c.png)
    no-repeat center;
  background-size: cover;
  z-index: 16;
}
.line_3 {
  position: absolute;
  width: 4.64px;
  height: 4.64px;
  top: 13.041px;
  left: 405.36px;
  background: url(../assets/login/images/df3fb383-c3f1-49d8-8453-c3f5d54ebb52.png)
    no-repeat center;
  background-size: cover;
  z-index: 17;
}
.line_4 {
  position: absolute;
  width: 3.112px;
  height: 4.924px;
  top: 15.854px;
  left: 400.223px;
  background: url(../assets/login/images/f9382097-6bb0-4511-a12c-2365d809cd3b.png)
    no-repeat center;
  background-size: cover;
  z-index: 19;
}
.line_5 {
  position: absolute;
  width: 3.112px;
  height: 4.924px;
  top: 15.868px;
  left: 392.375px;
  background: url(../assets/login/images/85cd4536-6bdb-4b00-af8c-a3166e4a19b6.png)
    no-repeat center;
  background-size: cover;
  z-index: 18;
}
.password_none {
  display: block;
  position: relative;
  /* height: 25px; */
  /* top: -20px; */
  left: 65px;
  /* margin: 6px 0 0 65px; */
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 22;
}
.flex_row_b {
  position: relative;
  width: 410px;
  height: 28px;
  margin: 16px 0 0 65px;
  z-index: 32;
}
.remember_password {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 0;
  left: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 30;
}
.forgot_password {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 0;
  left: 310px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 5;
}
.rectangle_6 {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: -5px;
  border: 1px solid #505f98;
  z-index: 32;
  border-radius: 5px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 410px;
  margin: 25px 0 0 65px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background: #505f98;
  border: none;
  z-index: 7;
  border-radius: 15px;
}
.confirm {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 34px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  z-index: 8;
}
.text_9 {
  display: block;
  position: relative;
  height: 25px;
  margin: 15px 0 0 171px;
  color: #f40d0d;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
.text_a {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 28px;
  top: 727px;
  left: 830px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
