:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1440px;
    height: 1024px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
  }
  .rectangle {
    position: relative;
    width: 1440px;
    height: 100px;
    margin: 0 0 0 0;
    background: #505f98;
    z-index: 15;
  }
  .koi_speak {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 55px;
    top: calc(50% - 28px);
    left: calc(50% - 110px);
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 16;
  }
  .group {
    position: relative;
    width: 120.986px;
    height: 110px;
    margin: 99px 0 0 658.014px;
    background: url(../assets/record_pages/target_score/dda91f8d-ed10-43b8-9b0e-5ecccd097eb3.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 1;
  }
  .email_address {
    position: relative;
    width: 1200px;
    height: 103px;
    margin: 39px 0 0 120px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 38.192px;
    text-align: left;
  }
  .email_input {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 38.192px;
    text-align: left;
  }
  .sample_report {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 900;
    line-height: 38.192px;
    text-align: left;
  }
  .dot {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 38.192px;
    text-align: left;
  }
  .next_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 308px;
    height: 60px;
    margin: 433px 0 0 566px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #505f98;
    border: none;
    z-index: 12;
    border-radius: 15px;
  }
  .submit_button {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 33px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 32.736px;
    text-align: left;
    white-space: nowrap;
    z-index: 13;
  }
  .frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 134px;
    height: 50px;
    top: 505px;
    left: 475px;
    padding: 0 10px 0 10px;
    background: #ffffff;
    z-index: 4;
  }
  .email {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 50px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 36px;
    font-weight: 900;
    line-height: 50px;
    text-align: left;
    white-space: nowrap;
    z-index: 5;
  }
  .rectangle_1 {
    position: absolute;
    width: 560px;
    height: 80px;
    top: 530px;
    left: 50%;
    background: #ffffff;
    border: 5px solid #505f98;
    transform: translate(-50%, 0);
    z-index: 3;
    overflow: hidden;
    border-radius: 20px;
  }
  .enter_email {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 43px;
    width: 560px;
    top: 16px;
    left: 19px;
    color: black;
    font-family: Nunito, var(--default-font-family);
    font-size: 28px;
    font-weight: 500;
    line-height: 39.2px;
    text-align: left;
    white-space: nowrap;
    border: none;
    z-index: 6;
  }
  .enter_email::placeholder {
    color: black;  /* 设置 placeholder 的颜色 */
  }
  .frame_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 232px;
    height: 50px;
    top: 640px;
    left: 475px;
    padding: 0 10px 0 10px;
    background: #ffffff;
    z-index: 9;
  }
  .target_band {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 50px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 36px;
    font-weight: 900;
    line-height: 50px;
    text-align: left;
    white-space: nowrap;
    z-index: 10;
  }
  .rectangle_3 {
    position: absolute;
    width: 560px;
    height: 80px;
    top: 665px;
    left: 50%;
    background: #ffffff;
    border: 5px solid #505f98;
    transform: translate(-50%, 0);
    z-index: 8;
    overflow: hidden;
    border-radius: 20px;
  }
  .enter_target_band {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 43px;
    top: 16px;
    left: 19px;
    color: black;
    font-family: Nunito, var(--default-font-family);
    font-size: 28px;
    font-weight: 500;
    line-height: 39.2px;
    text-align: left;
    white-space: nowrap;
    width: 600px;
    border: none;
    z-index: 11;
  }
  .enter_target_band:focus {
    border-color: transparent;
  }
