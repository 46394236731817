:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 1653px;
  margin: 0 auto;
  font-size: 0px;
  background: #ffffff;
  overflow: hidden;
}
.user_agreement {
  display: block;
  position: relative;
  height: 42px;
  margin: 21px 0 0 580px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  text-align: left;
  white-space: nowrap;
}
.ai_ielts_speaking_assessment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 1200px;
  height: 1270px;
  margin: 20px 0 0 40px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  text-overflow: initial;
  z-index: 1;
  overflow: hidden;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 360px;
  height: 40px;
  margin: 30px 0 0 460px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background: #505f98;
  border: none;
  z-index: 2;
  border-radius: 15px;
}
.close {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 34px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}