:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 832px;
  margin: 0 auto;
  background: #f7f7fa;
  overflow: hidden;
}
.frame {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
  position: relative;
  width: 270px;
  margin: 787px 0 0 505px;
}
.about_us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 1;
}
.about_us_1 {
  cursor: pointer;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.about_us_2 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 3;
}
.about_us_terms {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 4;
}
.about_us_privacy {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 5;
}
.about_us_help {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
.about_us_3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 7;
}
.about_us_4 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 8;
}
.flex_row_aaf {
  position: absolute;
  width: 1220px;
  height: 209px;
  top: 30px;
  left: 30px;
  z-index: 4;
}
.logo_tentative {
  position: absolute;
  width: 212px;
  height: 120px;
  top: 0;
  left: 0;
  background: url(../assets/main_page/images/13d134b8c6623a3c97a4772a4dbec54b24cacf65.png)
    no-repeat center;
  background-size: cover;
  z-index: 104;
  border-radius: 15px;
}
.summary_column {
  position: absolute;
  width: 980px;
  height: 209px;
  top: 0;
  left: 240px;
  background: #ffffff;
  z-index: 42;
  border-radius: 15px;
}
.image {
  position: absolute;
  width: 339px;
  height: 198px;
  top: 8px;
  left: 636px;
  background: url(../assets/main_page/images/339f0ff7c7a24a8c8a390d34b81672405c962083.png)
    no-repeat center;
  background-size: cover;
  opacity: 0.25;
  z-index: 47;
}
.study_day {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 34px;
  top: 12px;
  left: calc(50% - 144px);
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 33.6px;
  text-align: left;
  white-space: nowrap;
  z-index: 79;
}
.image_5 {
  position: absolute;
  width: 295px;
  height: 172px;
  top: 34px;
  left: 1px;
  background: url(../assets/main_page/images/339f0ff7c7a24a8c8a390d34b81672405c962083.png)
    no-repeat center;
  background-size: cover;
  opacity: 0.25;
  z-index: 45;
}
.image_6 {
  position: absolute;
  width: 295px;
  height: 172px;
  top: 34px;
  left: 326px;
  background: url(../assets/main_page/images/339f0ff7c7a24a8c8a390d34b81672405c962083.png)
    no-repeat center;
  background-size: cover;
  opacity: 0.25;
  z-index: 46;
}
.frame_7 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 598px;
  height: 93px;
  top: 67px;
  left: 50%;
  transform: translate(-50.25%, 0);
  z-index: 48;
}
.stats_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 116px;
  z-index: 49;
}
.frame_8 {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 82px;
  z-index: 50;
}
.frame_9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 52px;
  padding: 10px 5px 10px 5px;
  z-index: 51;
}
.number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 42px;
  height: 49px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 70px;
  font-weight: 900;
  line-height: 49px;
  text-align: center;
  white-space: nowrap;
  z-index: 52;
}
.frame_a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 30px;
  padding: 10px 0 10px 0;
  z-index: 53;
}
.text_tian {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 30px;
  height: 30px;
  left: 20px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  z-index: 54;
}
.textTianOffset {
  left: 20px;
}

.textTianNoOffset {
  left: 0px;
}
.frame_b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  padding: 0 10px 0 10px;
  z-index: 55;
}
.text-lian-xu_da_ka {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 96px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 56;
}
.statistic_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 116px;
  z-index: 57;
}
.frame_c {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 94px;
  z-index: 58;
}
.frame_d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 94px;
  padding: 10px 5px 10px 5px;
  z-index: 59;
}
.number_e {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 84px;
  height: 49px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 70px;
  font-weight: 900;
  line-height: 49px;
  text-align: center;
  white-space: nowrap;
  z-index: 60;
}
.frame_f {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  padding: 0 10px 0 10px;
  z-index: 61;
}
.text-lian-xu-da_ka_10 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 96px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 62;
}
.statistic_banner_11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 140px;
  z-index: 63;
}
.frame_12 {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 123px;
  z-index: 64;
}
.frame_13 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 94px;
  padding: 10px 5px 10px 5px;
  z-index: 65;
}
.number_14 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 84px;
  height: 49px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 70px;
  font-weight: 900;
  line-height: 49px;
  text-align: center;
  white-space: nowrap;
  z-index: 66;
}
.frame_15 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 29px;
  padding: 10px 0 10px 0;
  z-index: 67;
}
.text_tian_percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 29px;
  height: 30px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  z-index: 68;
}
.frame_16 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  padding: 0 10px 0 10px;
  z-index: 69;
}
.text-zhen-ti-ku_yi_shua {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 70;
}
.statistic_banner_17 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 196px;
  z-index: 71;
}
.frame_18 {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 196px;
  z-index: 72;
}
.frame_19 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 136px;
  padding: 10px 5px 10px 5px;
  z-index: 73;
}
.span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 126px;
  height: 49px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 70px;
  font-weight: 900;
  line-height: 49px;
  text-align: center;
  white-space: nowrap;
  z-index: 74;
}
.frame_1a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 60px;
  padding: 10px 0 10px 0;
  z-index: 75;
}
.span_1b {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 60px;
  height: 30px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  z-index: 76;
}
.frame_1c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  padding: 0 10px 0 10px;
  z-index: 77;
}
.text_lian_xu_da_ka {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 168px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 78;
}



.text_lian_xu_da_ka_10 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 168px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 78;
}

.text_zhen_ti_ku_yi_shua {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 168px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 78;
}

.span_1d {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 168px;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 78;
}
.flex_row_efd {
  position: absolute;
  height: 601px;
  top: 169px;
  right: 30px;
  left: 30px;
  z-index: 80;
}
.sidebar {
  position: absolute;
  width: 212px;
  height: 601px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 80;
  border-radius: 15px;
}
.frame_1e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 185px;
  height: 211px;
  margin: 20px 0 0 14px;
  z-index: 88;
}
.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  background: #dcdfea;
  border: none;
  z-index: 89;
  border-radius: 15px;
}
.home {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 90;
  overflow: hidden;
}
.icon {
  position: relative;
  width: 18.667px;
  height: 17.625px;
  margin: 0.88px 0 0 0.67px;
  background: url(../assets/main_page/images/979b6dd9-bf17-4e5c-a7b1-4596f17676db.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 91;
}
.my_home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 92;
}
.sidebar_my_home {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 93;
  border-radius: 15px;
}
.sidebar_my_home:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.mock_exam {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 94;
}
.trophy {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 95;
  overflow: hidden;
}
.icon_1f {
  position: relative;
  width: 18.667px;
  height: 18.667px;
  margin: 0.67px 0 0 0.67px;
  background: url(../assets/main_page/images/62f6ce2c-11e5-4d83-b019-a5fdbc689b09.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 96;
}
.my_home_20 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 97;
}
.sidebar_my_home_21 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 98;
  border-radius: 15px;
}
.sidebar_my_home_21:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.oral_practice {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/55deef43-5cfd-495b-9e98-432cdb1eae48.png)
    no-repeat center;
  background-size: cover;
  z-index: 99;
}
.my_home_22 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 100;
}
.sidebar_homepage {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 101;
  border-radius: 15px;
}
.sidebar_homepage:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.mock_exam_record {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/4a8ff943-e262-4d80-becb-15c423b36e5c.png)
    no-repeat center;
  background-size: cover;
  z-index: 102;
}
.homepage_history {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 103;
}
.divider {
  position: relative;
  width: 150px;
  margin: 167px 0 0 25px;
  background: #f7f7fa;
  z-index: 86;
}
.line {
  position: absolute;
  width: 153px;
  top: -1.5px;
  bottom: -1.5px;
  left: 50%;
  background: url(../assets/main_page/images/3eec1565-820d-4dc7-aee7-2520b94c0a7a.png)
    no-repeat center;
  background-size: 100% 100%;
  transform: translate(-50%, 0);
  z-index: 87;
}
.sidebar_homepage_23 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 15px;
  position: relative;
  width: 190px;
  height: 61px;
  margin: 15px 0 0 11px;
  padding: 10px 15px 10px 15px;
  z-index: 83;
  border-radius: 15px;
}
.sidebar_homepage_23:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.my_account {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/5dc4ee76-3edb-4a3e-b86f-1d7938368380.png)
    no-repeat center;
  background-size: cover;
  z-index: 84;
}
.homepage_account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 85;
}
.sidebar_homepage_24 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 15px;
  position: relative;
  width: 50px;
  margin: 83px 0 0 159px;
  padding: 10px 15px 10px 15px;
  z-index: 81;
  border-radius: 15px;
}
.help {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/1540f2e2-52bb-4e40-9f7c-6df470586353.png)
    no-repeat center;
  background-size: cover;
  z-index: 82;
}
.practice_ready {
  position: absolute;
  height: 511px;
  top: 90px;
  right: 0;
  left: 240px;
  background: #ffffff;
  z-index: 9;
  border-radius: 15px;
}
.frame_25 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 49px;
  position: relative;
  width: 888px;
  margin: 30px 0 0 46px;
  z-index: 10;
}
.divider_26 {
  flex-shrink: 0;
  position: relative;
  width: 200px;
  z-index: 11;
}
.line_27 {
  position: absolute;
  width: 101.5%;
  height: Infinity%;
  top: -Infinity%;
  left: -0.75%;
  background: url(../assets/main_page/images/bab91a3c-10a9-41fb-b26f-dd3d5c5493f2.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 12;
}
.start_practice {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}
.divider_28 {
  flex-shrink: 0;
  position: relative;
  width: 200px;
  z-index: 14;
}
.line_29 {
  position: absolute;
  width: 101.5%;
  height: Infinity%;
  top: -Infinity%;
  left: -0.75%;
  background: url(../assets/main_page/images/909e09cd-1737-4d1b-88c6-41ee273b4b94.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 15;
}
.flex_row_ba {
  position: relative;
  width: 768px;
  height: 237px;
  margin: 60px 0 0 106px;
  z-index: 24;
  
}
.frame_2a {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 280px;
  height: 222px;
  top: 0;
  left: 50%;
  padding: 20px 80px 20px 80px;
  background: #535f94;
  border: 5px solid #535f94;
  transform: translate(-137.14%, 0);
  z-index: 19;
  border-radius: 15px;

  transition: transform 0.2s ease, box-shadow 0.2s ease; 
}
.frame_2a:hover {
  transform: translate(-137.14%, 0) scale(0.95); /* 缩小按钮，模拟下压效果 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 添加阴影增强下压效果 */
}
.mock_exam_2b {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 42px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 42px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}
.simulation_exam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 280px;
  height: 222px;
  top: 0;
  left: 50%;
  padding: 20px 80px 20px 80px;
  background: #535f94;
  border: 5px solid #535f94;
  transform: translate(37.14%, 0);
  z-index: 24;
  opacity: 0.5;
  border-radius: 15px;
}
.oral_practice_2c {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 42px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 42px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}
.rectangle {
  position: absolute;
  width: 280px;
  height: 222px;
  top: 15px;
  left: 0;
  background: #3d466e;
  z-index: 18;
  border-radius: 15px;
}
.text_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: relative;
  width: 101px;
  margin: 140px 0 0 90px;
  z-index: 40;
}
.report_voucher {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 21px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
  z-index: 41;
}
.rectangle_2d {
  position: absolute;
  width: 280px;
  height: 222px;
  top: 15px;
  left: 488px;
  background: #3d466e;
  z-index: 23;
  border-radius: 15px;
}
.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 711px;
  height: 59px;
  margin: 25px 0 0 106px;
  z-index: 33;
}
.frame_2e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 295px;
  z-index: 26;
}
.text_icon_2f {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  z-index: 27;
}
.done_ring_round {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/1983c423-c0eb-45fc-9bf7-7b19f8a593dd.png)
    no-repeat center;
  background-size: cover;
  z-index: 28;
}
.random_exam {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 27px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 29;
}
.text_icon_30 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  z-index: 30;
}
.done_ring_round_31 {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/7b435e9d-5357-4199-ad65-9e64d3d7a430.png)
    no-repeat center;
  background-size: cover;
  z-index: 31;
}
.ai_scoring {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  white-space: nowrap;
  z-index: 32;
}
.frame_32 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 223px;
  z-index: 33;
}
.text_icon_33 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  z-index: 34;
}
.done_ring_round_34 {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/5964af98-33ba-47b2-a6a3-fdc1e5b60cb5.png)
    no-repeat center;
  background-size: cover;
  z-index: 35;
}
.massive_question_bank {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 27px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 36;
}
.text_icon_35 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  z-index: 37;
}
.done_ring_round_36 {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/e3abdb17-33db-46cc-a1af-0f9d5f19eed9.png)
    no-repeat center;
  background-size: cover;
  z-index: 38;
}
.oral_practice_37 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  white-space: nowrap;
  z-index: 39;
}

.jump_main_container {
  position: relative;
  top: -600px;
  left: 120px;
  width: 578px;
  height: 356px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.jump_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 308px;
  margin: 0 0 0 135px;
  padding: 10px 10px 10px 10px;
  z-index: 81;
}
 .jump_ready_to_go {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 54px;
  color: #535f94;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 36px;
  font-weight: 900;
  line-height: 54px;
  text-align: left;
  white-space: nowrap;
  z-index: 82;
}
.jump_line {
  position: relative;
  width: 521px;
  height: 5px;
  margin: -0.5px 0 0 28.5px;
  top: -10px;
  background-color: #505F98;
  background: url(../assets/main_page/images/line.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 100;
}
 .jump_text_2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 520px;
  height: 81px;
  margin: 13.5px 0 0 29px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}
 .jump_frame_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 253px;
  margin: 10px 0 0 162px;
  cursor: pointer;
  background: #f7f7fa;
  border: none;
  z-index: 83;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
 .jump_frame_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 164px;
  padding: 5px 0 5px 0;
  z-index: 84;
}
 .jump_my_points {
  flex-shrink: 0;
  position: relative;
  width: 164px;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 85;
}
 .jump_my_points_1 {
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
.jump_points {
  position: relative;
  color: #535f94;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
 .jump_frame_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 110px;
  padding: 0 10px 0 10px;
  z-index: 86;
}
 .jump_frame_3 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 87;
}
 .jump_purchase_score {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 88;
}
 .jump_flex_row_a {
  position: relative;
  width: 473px;
  height: 52px;
  margin: 22px 0 0 53px;
  z-index: 98;
}
 .jump_small_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 47.15%;
  height: 88.71%;
  top: 0;
  left: 0;
  padding: 10px 20px 10px 20px;
  background: #505f98;
  z-index: 94;
  border-radius: 15px;
}
 .jump_i_am_thinking {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 95;
}
 .jump_small_button_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 47.15%;
  height: 88.71%;
  top: 0;
  left: 52.85%;
  padding: 10px 20px 10px 20px;
  background: #d453a8;
  z-index: 98;
  border-radius: 15px;
}
 .jump_start_mock_exam {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 99;
}
.jump_rectangle {
  position: absolute;
  width: 47.15%;
  height: 95.16%;
  top: 4.84%;
  left: 0;
  background: #3e476f;
  z-index: 93;
  border-radius: 15px;
}
.jump_rectangle_5 {
position: absolute;
width: 47.15%;
height: 95.16%;
top: 4.84%;
left: 52.85%;
background: #782c5e;
z-index: 97;
border-radius: 15px;
}

.help_main_container {
  left: 100px;
  top: -600px;
  position: relative;
  width: 346px;
  height: 491px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  z-index: 80;
}
.help_close {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20.085px;
  margin: 16px 0 0 310px;
  background: url(../assets/main_page/images/help_close.png)
    no-repeat center;
  background-size: cover;
  z-index: 3;
}
.help_close:hover {
  border: 2px solid red; /* 红色边框 */
}
.help_add_wechat {
  display: block;
  position: relative;
  height: 25px;
  margin: 8.915px 0 0 119px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.help_get_materials {
  display: block;
  position: relative;
  height: 25px;
  margin: 10px 0 0 47px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
}
.help_image {
  position: relative;
  width: 266px;
  height: 363px;
  margin: 23px 0 0 40px;
  background: url(../assets/main_page/images/help_image.png)
    no-repeat center;
  background-size: cover;
}